// Polyfills
import 'promise-polyfill/src/polyfill';

// NPM packages
import lozad from 'lozad';

// Lozad
window.lozadObserver = lozad('.lozad', {
    loaded: function (el) {
        const cropPos = el.getAttribute('data-crop-pos');
        if (cropPos) {
            const img = el.getElementsByTagName('img');
            if (img) {
                img[0].style.objectPosition = cropPos;
            }
        }
    }
});

// Import our CSS
import styles from '../css/app.pcss';

// App main
const main = async () => {
    // Async load the vue module
    const {default: Vue} = await import('vue');
    const {default: VModal} = await import('vue-js-modal');
    const {default: VTooltip} = await import('v-tooltip');

    /* VeeValidate */
    const {ValidationProvider, extend, localize} = await import('vee-validate');
    const { required, email } = await import('vee-validate/dist/rules');
    const {default: nl} = await import('vee-validate/dist/locale/nl.json');
    const {default: fr} = await import('vee-validate/dist/locale/fr.json');

    Vue.use(VModal, {dynamic: true, injectModalsContainer: true})
    Vue.use(VTooltip)

    Vue.component('ValidationProvider', ValidationProvider);

    /*
    -------------------------
    VeeValidate config
    -------------------------
    */
    localize({
        fr: {
            messages: {
                required: 'Le champ est obligatoire.',
                text: 'le texte doit comporter plus d\'un caractère.',
                email: 'Veuillez fournir une adresse email valide',
                url: 'Veuillez fournir une URL valide.',
                phone: 'Veuillez fournir un numéro de téléphone valide.'
            },
        },
        nl: {
            messages: {
                required: 'Dit veld is verplicht.',
                text: 'Tekst moet langer zijn dan 1 karakter.',
                email: 'Geef een geldig e-mailadres op.',
                url: 'Geef een geldige url op.',
                phone: 'Geef een geldig telefoonnummer op.'
            },
        }
    });

    extend('required', required);
    extend('email', email);
    extend("text", (value) => {
        if (value.length >= 2) {
            return true;
        }
    });
    extend("url", (value) => {
        const expression = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
        const regex = new RegExp(expression);
        if (value.match(regex)) return true;
    });
    extend("phone", (value) => {
        const expression = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g;
        const regex = new RegExp(expression);
        if (value.match(regex)) return true;
    })



    // Create our vue instance
    const vm = new Vue({
        el: "#app",
        delimiters: ['${', '}'],
        components: {
            'testimonials-list': () => import(/* */ '../vue/TestimonialsList.vue'),
            'video-testimonail': () => import(/* */ '../vue/VideoTestimonial.vue'),
            'book-now-modal': () => import(/* */ '../vue/BookNowModal.vue'),
            'book-now-modal-cta': () => import(/* */ '../vue/BookNowModalCta.vue'),

            /* Form field validation */
            'form-single-line': () => import(/* */ '../vue/form/SingleLineField.vue'),
            'text-area': () => import(/* */ '../vue/form/Textarea.vue'),
        },
        data() {
            return {
                showMobileMenu: false,
            }
        },
        methods: {
            showHideMobileMenu() {
                this.showMobileMenu = !this.showMobileMenu;
            }
        },
        mounted() {
            window.lozadObserver.observe();
            /*Set language for VeeValidate*/
            if (document.documentElement.lang === 'nl') localize('nl');
            if (document.documentElement.lang === 'fr') localize('fr');
        },
    });
    return vm;
};

// Execute async function
main().then((vm) => {
});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}
